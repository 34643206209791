import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import slide1 from '../assets/images/banner/banner_new-1.webp';
import slide3 from '../assets/images/banner/banner_new-2.webp'
import slide2 from '../assets/images/banner/banner_new-3.webp'

const MainBanner = () => {
    return (
        <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            loop
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
        >
            <SwiperSlide>
                <div className="main-slide">
                    <img src={slide2} className="img-responsive banner-img" alt="best childcare in Oak Harbor"/>
                    <div className="slide-item header-text remove-bg">
                    <span className='text-light h5-text'>Guiding Tiny Steps Into a Bright</span>
                        <span className='h5-text text-green'> Future</span>
                        <br />
                        <div className="page-scroll">
                            <a className="btn" href="/contact">Contact Us</a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className="main-slide">
                    <img src={slide1} className="img-responsive banner-img" alt="best childcare in Oak Harbor" />
                    <div className="slide-item header-text remove-bg">
                        <span className='text-light h5-text'>Guiding Tiny Steps Into a Bright</span>
                        <span className='h5-text text-green'> Future</span>
                        <br />
                        <div className="page-scroll">
                            <a className="btn" href="/contact">Contact Us</a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>

            <SwiperSlide>
                <div className="main-slide">
                    <img src={slide3} className="img-responsive banner-img" alt="best childcare in Oak Harbor" />
                    <div className="slide-item header-text remove-bg">
                        <span className='text-light h5-text'>Guiding Tiny Steps Into a Bright</span>
                        <span className='h5-text text-green'> Future</span>
                        <br />
                        <div className="page-scroll">
                            <a className="btn" href="/contact">Contact Us</a>
                        </div>
                    </div>
                </div>
            </SwiperSlide>
        </Swiper>
    )
}

export default MainBanner