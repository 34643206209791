import React, { useState, useEffect } from "react";

const PopUp = () => {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => setShowModal(false);
    const handleOpen = () => setShowModal(true);

    return (
        <>
            <button
                className="sticky-button btn btn-primary"
                onClick={handleOpen}
                style={{
                    position: "fixed",
                    right: "5px",
                    top: "50%",
                    // transform: "translateY(-50%) rotate(-90deg)", 
                    transformOrigin: "right center", 
                    zIndex: "1000",
                    cursor: "pointer",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    whiteSpace: "nowrap", 
                    borderRadius:"none",
                    // fontSize: "12px",
                }}
            >
                Saturday Childcare <br /> Coming Soon!
            </button>


            <div
                className={`announcement-popup modal ${showModal ? "show d-block" : ""}`}
                tabIndex="-1"
                role="dialog"
                style={{
                    backgroundColor: showModal ? "rgba(0, 0, 0, 0.5)" : "",
                    display: showModal ? "block" : "none",
                }}
                aria-hidden={!showModal}
            >
                <div
                    className="modal-dialog"
                    role="document"
                    style={{
                        top: "27%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "90%",
                        maxWidth: "600px",
                    }}
                >
                    <div className="modal-content">
                        <div className="modal-header text-center" style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <h3 className="modal-title">Saturday Childcare <br /> Coming Soon!</h3>
                                <p style={{ fontSize: "14px", lineHeight: "unset" }}>We’re thrilled to announce that Saturday Childcare will soon be available! If you’re interested, let us know by filling out our Saturday Childcare Survey Form.</p>
                            </div>
                            <a
                                type="button"
                                className="btn-close"
                                aria-label="Close"
                                onClick={handleClose}
                                style={{ cursor: "pointer" }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="#fff"
                                    className="bi bi-x"
                                    viewBox="0 0 16 16"
                                >
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 6.793l2.646-2.647a.5.5 0 1 1 .708.708L8.707 7.5l2.647 2.646a.5.5 0 0 1-.708.708L8 8.207l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 7.5 4.646 4.854a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </a>
                        </div>
                        <div className="modal-body text-center">
                            <p><strong>Interested?</strong> Click on the form link below to share your interest with us! </p>
                            <a type="button" href="https://forms.gle/34hx2urHApcWaXJQ7" rel="noopener noreferrer" target='_blank' className="btn btn-primary" style={{ marginBottom: "15px" }}>Fill Out the Survey Form</a>
                            <p>📅 <strong>Stay Updated:</strong> Be the first to know when spots open!</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopUp;